/* TimeEntriesScreen.module.css */
.container {
    display: flex;
    flex-direction: column;
}

.title {
    flex-grow: 1;
    text-align: right;
}

.dropdownContainer {
    margin: 20px;
}

.tableContainer {
    margin: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 2px;
    text-align: right;
}

.table tr:hover {
    background-color: #c28bcc;
}
.rowNormal {
    background-color: #fff;
}

.rowNoClockOut {
    background-color: #ebb6f5; /* Light red background for rows with no clock out */
}

.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
/* TimeEntriesScreen.module.css */

.container {
    direction: rtl; /* Enable RTL layout for the container */
    /* other styles */
}

.selectContainer {
    /* Make sure the select container supports RTL layout */
    direction: rtl;
}

/* Additional RTL-specific styles if needed */
.table {
    direction: rtl;
}

.rowNormal {
    direction: rtl;
}

.rowNoClockOut {
    direction: rtl;
}

.modalContainer {
    direction: rtl;
    min-width: 300px;
}
.modalDescription{
    margin-bottom: 20px;
}
/* Add any other necessary RTL styles */

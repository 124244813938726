/* Apply RTL direction for the entire container */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    direction: rtl; /* Set text direction to right-to-left */
    background-color: #f0f0f0; /* Optional: background color */
}

.formContainer {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.form {
    display: flex;
    flex-direction: column;
}

.input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    direction: rtl; /* Ensure input text direction is right-to-left */
}

.button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.button:hover:not(:disabled) {
    background-color: #0056b3;
}

.error {
    color: red;
    text-align: center;
    margin-top: 10px;
}

.container {
    text-align: center;
    padding: 20px;
}

.title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.button {
    padding: 10px 20px;
    font-size: 16px;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.responseModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.quillEditor {
    height: 200px;
    margin-top: 20px;
    margin-bottom: 40px;
}
